
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappsForm from "../../components/webapps/form"

const negosyonowAPI = require("../../../lib/requestNegosyownow")
const webappsAPI = require("../../../lib/requestWebapps")

const ENTITYID="nnchildtag"

const NNTAGID_BANNER=40

const searchFields = [
	{"label":"Order", "dbfield": "nnchildtag_order", "type": "integer", "filtertype": "integer"},
	{"label":"Image", "dbfield": "nnchildtag_image", "type": "image", "filtertype": "image"},
	{"label":"Link", "dbfield": "nnchildtag_url", "type": "text", "filtertype": "text"},
];


const formFields = [
	[
		{"label":"Order", "field": "nnchildtag_order", "value": "", "input": "integer", "mode": "required"},
		{"label":"Banner", "field": "nnchildtag_image", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1200, "imageheight":480, "imageformat":"jpg", "uploadtype": "https","filehost": "cdn.ngnw.ph", "filelocation":"images/banner"},
		{"label":"Link", "field": "nnchildtag_url", "value": "", "input": "textbox", "mode": "normal", "info":"start after https://negosyonow.com/, or enter full address"},
	]
];




const BannerPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	function customDeleteDone(entity, pkid, payloadlist, token, subtablelist, callback)
	{
		negosyonowAPI.buildEntityId(NNTAGID_BANNER,token,"tag").then(nnresponse => {
			callback();
		});
	}

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		//callback({"status":"Error", "message":"Debug"}); return;
		var pkid = 0;
		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}

		params.nntag_id = NNTAGID_BANNER;
		if (!params.hasOwnProperty("documentid")) {
			params.nntagalias_id = 0;
		}
		if (params.hasOwnProperty("nnchildtag_url")) {
			if (params.nnchildtag_url) {
				// Remove prefix, optimal for navigation speed
				params.nnchildtag_url = params.nnchildtag_url.replace("https://negosyonow.com/", "");
			}
		}

		if (params.hasOwnProperty("nnchildtag_order")) {
			webappsAPI.sortRecords(entity, token, "nnchildtag_order", pkid, params.nnchildtag_order, "nntag_id="+NNTAGID_BANNER, function(sortresponse) {
				saveChanges(entity, params, token, payloadlist, subformpayloadlist, callback);
			});
			return;
		}
		saveChanges(entity, params, token, payloadlist, subformpayloadlist, callback);
	}

	function saveChanges(entity, params, token, payloadlist, subformpayloadlist, callback)
	{
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				negosyonowAPI.buildEntityId(NNTAGID_BANNER,token,"tag").then(nnresponse => {
					callback(response);
				});
			} else {
				callback(response);
			}
		});
	}

	return <Layout fullPath={location.pathname}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={true}
				allowdelete={true}
				pagetitle={pagetitle}
				mobilerowtitlefield={["nnchildtag_order"]}
				searchFields={searchFields}
				editFields={formFields}
				newformFields={formFields}
				customSubmit={customSubmit}
				customDeleteDone={customDeleteDone}
				userSearchFilter={[
					{"field":"nntag_id", "operation":"=", "value": NNTAGID_BANNER}
				]}
				entity={ENTITYID}
				token={token} />
		</Layout>
}


export default BannerPage;
